// Подключение свайпера
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
new Swiper(".about-slider", {
  modules: [Navigation, Pagination],
  breakpoints: {
    1660: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    1200: {
      slidesPerView: 2.5,
      spaceBetween: 10,
    },
    991: {
      slidesPerView: 1.8,
      spaceBetween: 10,
    },
    767: {
      slidesPerView: 1.5,
      spaceBetween: 10,
    },
    320: {
      slidesPerView: 1.2,
      spaceBetween: 10,
    },
  },
});

import Aos from "aos";
Aos.init({
  once: true,
  duration: 1000,
  disable: "mobile",
});
